import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

function PrivateAuthHoc({component: Component, ...rest}) {
  const restData = useSelector((state) => state.auth.restData);
  const token = restData.token ? restData.token : '';
  return (
    <Route
      {...rest}
      render={(prop) =>
        token !== '' ? <Component {...prop} /> : <Redirect to={'/'} />
      }
    />
  );
}

export default PrivateAuthHoc;
