import * as actionTypes from '../../actionTypes';

const initialState = {
  purchaseList: [],
};

export default function purchaseReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_PURCHASE:
      return {
        ...state,
        purchaseList: action.payload,
      };
    case actionTypes.ADD_PURCHASE: {
      const addPurchase = [...state.purchaseList];
      addPurchase.push(action.payload);
      return {
        ...state,
        purchaseList: addPurchase,
      };
    }
    case actionTypes.LOG_OUT:
      return {
        purchaseList: [],
      };
    default:
      return state;
  }
}
