const initialState = {
  garbageList: [],
};

export default function garbageReducers(state = initialState, action) {
  switch (action.type) {
    case 'INIT_GARBAGE_LIST':
      return {
        ...state,
        garbageList: action.payload,
      };
    case 'UPDATE_FROM_GARBAGE':
      const updateItem = [...state.garbageList];
      const updateItemIndex = updateItem.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateItemIndex > -1) {
        updateItem[updateItemIndex] = action.payload;
      }
      return {
        ...state,
        garbageList: updateItem,
      };
    case 'LOG_OUT':
      return {
        garbageList: [],
      };
    default:
      return state;
  }
}
