import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import {useStyles} from '../styles';
import {useRouteMatch} from 'react-router';
import MenuSelection from '../../MenuSelection';
import DashboardIcon from '../svgs/DashboardIcon';
import PurchaseIcon from '../svgs/PurchaseIcon';
import LogoutIcon from '../svgs/LogoutIcon';
import {useDispatch} from 'react-redux';
import {push} from 'connected-react-router';
import {logout} from '../../../services/actions/authActions';
import RestInfo from './RestInfo';
import {useTranslation} from 'react-i18next';
import LangSelector from './LangSelector';
import ConfigureStockIcon from '../svgs/ConfigureStockIcon';

function MenuDrawer({open, handleClose}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const menus = [
    {
      label: t('Dashboard'),
      active: useRouteMatch('/dashboard') ? true : false,
      handleMenu: () => {
        handleClose();
        dispatch(push('/dashboard'));
      },
      icon: <DashboardIcon />,
    },
    {
      label: t('Configure Stock'),
      active: useRouteMatch('/configure-stock') ? true : false,
      handleMenu: () => {
        handleClose();
        dispatch(push('/configure-stock'));
      },
      icon: <ConfigureStockIcon />,
    },
    {
      label: t('Purchase/Invoice'),
      active: useRouteMatch('/purchase-invoice') ? true : false,
      handleMenu: () => {
        handleClose();
        dispatch(push('/purchase-invoice'));
      },
      icon: <PurchaseIcon />,
    },
    {
      label: t('Logout'),
      active: false,
      handleMenu: () => {
        handleClose();
        dispatch(logout());
      },
      icon: <LogoutIcon />,
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        <RestInfo />
        {menus.map((menu, index) => (
          <MenuSelection
            key={index}
            active={menu.active}
            label={menu.label}
            handleClick={menu.handleMenu}
          >
            {menu.icon}
          </MenuSelection>
        ))}
        <div className={classes.bottomArea}>
          <LangSelector />
          <div className={classes.version}>
            V-{window.localStorage.getItem('appVersion')}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MenuDrawer;
