import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';

export const getGarbageList = () => (dispatch) => {
  dispatch(setLoading(true));
  http
    .get(`${API_URL}/wastage`)
    .then((res) => {
      dispatch({type: 'INIT_GARBAGE_LIST', payload: res.data});
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
    });
};

export const addToGarbage = (data, succesCB) => (dispatch) => {
  dispatch(setLoading(true));
  http
    .post(`${API_URL}/wastage`, data)
    .then(() => {
      succesCB();
      dispatch(getGarbageList());
    })
    .catch(() => {
      dispatch(setLoading(false));
    });
};

export const deleteFromGarbage = (id, successCB) => (dispatch) => {
  dispatch(setLoading(true));
  http
    .delete(`${API_URL}/wastage/${id}`)
    .then((res) => {
      dispatch({type: 'UPDATE_FROM_GARBAGE', payload: res.data});
      successCB();
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
    });
};
