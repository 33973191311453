import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {http} from '../../http';

export const getUnits = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory/unit/${storeID}`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_STOCK_UNITS, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewUnit = (storeID, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory/unit/${storeID}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.ADD_STOCK_UNITS, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateUnit = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory/unit/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.UPDATE_STOCK_UNITS, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteUnit = (unitID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory/unit/${unitID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.DELETE_STOCK_UNIT, payload: unitID});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getInventorySuperCategory = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory/super_category/${storeID}`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SUPER_CATEGORY,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewSuperCategory = (storeID, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory/super_category/${storeID}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.ADD_STOCK_SUPER_CATEGORY,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateSuperCategory = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory/super_category/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.UPDATE_STOCK_SUPER_CATEGORY,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteSuperCategory = (superCategoryID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory/super_category/${superCategoryID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.DELETE_STOCK_SUPER_CATEGORY,
          payload: superCategoryID,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getInventoryCategory = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory/category/${storeID}?__type=ingredient`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_STOCK_CATEGORY, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewInventoryCategory = (storeID, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory/category/${storeID}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.ADD_STOCK_CATEGORY, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateInventoryCategory = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory/category/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.UPDATE_STOCK_CATEGORY, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteInventoryCategory = (categoryID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory/category/${categoryID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.DELETE_STOCK_CATEGORY,
          payload: categoryID,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getIngredientItem = () => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory_items?__type=ingredient`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_STOCK_ITEM, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewIngredientItem = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory_items`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.ADD_STOCK_ITEM, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateIngredientItem = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory_items/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.UPDATE_STOCK_ITEM, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteIngredientItem = (itemID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory_items/${itemID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.DELETE_STOCK_ITEM,
          payload: itemID,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getCutCodes = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory/cutcode/${storeID}`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_STOCK_CUTCODE, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewCutCode = (storeID, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory/cutcode/${storeID}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.ADD_STOCK_CUTCODE, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateCutCode = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory/cutcode/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({type: actionTypes.UPDATE_STOCK_CUTCODE, payload: res.data});
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteCutCode = (itemID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory/cutcode/${itemID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.DELETE_STOCK_CUTCODE,
          payload: itemID,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getSemiProcessedCategory = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory/category/${storeID}?__type=semi-processed`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_CATEGORY,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewSemiProcessedCategory = (storeID, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory/category/${storeID}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.ADD_STOCK_SEMI_PROCESSED_CATEGORY,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateSemiProcessedCategory = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory/category/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.UPDATE_STOCK_SEMI_PROCESSED_CATEGORY,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteSemiProcessedCategory = (categoryID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory/category/${categoryID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.DELETE_STOCK_SEMI_PROCESSED_CATEGORY,
          payload: categoryID,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getSemiProcessedItem = () => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory_items?__type=semi-processed`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewSemiProcessedItem = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory_items`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.ADD_STOCK_SEMI_PROCESSED_ITEM,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateSemiProcessedItem = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory_items/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.UPDATE_STOCK_SEMI_PROCESSED_ITEM,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteSemiProcessedItem = (itemID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory_items/${itemID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.DELETE_STOCK_SEMI_PROCESSED_ITEM,
          payload: itemID,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const getSemiProcessedRecipe = (item) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory_items/${item._id}/recipe`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {...item, recipe: res.data},
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {...item, recipe: {}},
        });
      });
  };
};

export const addSemiProcessedRecipe = (item) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    const data = {
      item_id: item._id,
      recipe: item.recipe && item.recipe.recipe,
    };
    http
      .post(`${API_URL}/inventory_items/${item._id}`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {...item, recipe: res.data},
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        toast.error('Unable to add recipe');
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {...item, recipe: {}},
        });
      });
  };
};

export const updateSemiProcessedRecipe = (item) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    const data = {
      item_id: item._id,
      recipe: item.recipe && item.recipe.recipe,
    };
    http
      .put(`${API_URL}/inventory_items/${item._id}/update`, data)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {...item, recipe: res.data},
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch(() => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        toast.error('Unable to add recipe');
        dispatch({
          type: actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE,
          payload: {...item, recipe: {}},
        });
      });
  };
};

export const getStockTemplates = (storeID) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .get(`${API_URL}/inventory/templates/${storeID}`)
      .then((res) => {
        dispatch({
          type: actionTypes.INIT_STOCK_TEMPLATES,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const addNewStockTemplate = (storeID, data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .post(`${API_URL}/inventory/templates/${storeID}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.ADD_STOCK_TEMPLATES,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const updateStockTemplates = (data, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .put(`${API_URL}/inventory/templates/${data._id}`, data)
      .then((res) => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.UPDATE_STOCK_TEMPLATES,
          payload: res.data,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};

export const deleteStockTemplates = (itemID, successCB) => {
  return (dispatch) => {
    dispatch({type: actionTypes.STOCK_LOADING, payload: true});
    http
      .delete(`${API_URL}/inventory/templates/${itemID}`)
      .then(() => {
        if (successCB) {
          successCB();
        }
        dispatch({
          type: actionTypes.DELETE_STOCK_TEMPLATES,
          payload: itemID,
        });
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
      })
      .catch((err) => {
        dispatch({type: actionTypes.STOCK_LOADING, payload: false});
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };
};
