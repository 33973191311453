import React, {useEffect, useState} from 'react';
import {useStyles} from './styles';
import MenuIcon from './svgs/MenuIcon';
import IconButton from '@material-ui/core/IconButton';
import MenuDrawer from './components/MenuDrawer';
import {push} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import HotMenu from './components/HotMenu';
import {useTranslation} from 'react-i18next';

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const language = useSelector((state) =>
    state.auth.restaurant && state.auth.restaurant.opted_languages
      ? state.auth.restaurant.opted_languages
      : []
  );

  useEffect(() => {
    if (
      language.includes(window.localStorage.i18nextLng.split('-')[0]) ||
      window.localStorage.i18nextLng.split('-')[0] === 'en'
    ) {
      i18n.changeLanguage(window.localStorage.i18nextLng.split('-')[0]);
    } else {
      i18n.changeLanguage('en');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.leftDiv}>
        <div className={classes.flexBox}>
          <IconButton onClick={() => setMenuOpen(true)}>
            <MenuIcon />
          </IconButton>
          <div
            className={classes.titleText}
            onClick={() => {
              if (
                window.location.pathname &&
                !window.location.pathname.includes('dashboard')
              ) {
                dispatch(push('/dashboard'));
              }
            }}
          >
            {t('WTF Inventory')}
          </div>
        </div>
      </div>
      <div className={classes.rightDiv}>
        <HotMenu />
      </div>
      <MenuDrawer open={menuOpen} handleClose={() => setMenuOpen(false)} />
    </div>
  );
}

export default Header;
