import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';
import {getVendors} from '../vendorActions';
import {getInventoryList} from '../inventoryActions';
import {getIngredientItem, getSemiProcessedItem} from '../stockActions';

export const getInvoices = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/store_invoice?limit=1000&page=1`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_INVOICES, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const removeInvoice = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/store_invoice/${id}`)
      .then(() => {
        dispatch({type: actionTypes.REMOVE_INVOICE, payload: id});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addInvoice = (data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/store_invoice`, data)
      .then((res) => {
        dispatch({type: actionTypes.ADD_INVOICE, payload: res.data});
        dispatch(getVendors());
        dispatch(getIngredientItem());
        dispatch(getSemiProcessedItem());
        dispatch(getInventoryList());
        cb();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const settleKhata = (vendorID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/vendor/${vendorID}/khata`, {})
      .then(() => {
        dispatch(setLoading(false));
        dispatch(getVendors());
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};
