import * as actionTypes from '../../actionTypes';

const initialState = {
  invoices: {
    result: [],
    __metadata: {},
  },
};

export default function invoiceReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case actionTypes.REMOVE_INVOICE:
      const removeInvoice = [...state.invoices.result];
      const removeIndex = removeInvoice.findIndex(
        (a) => a._id === action.payload
      );
      if (removeIndex > -1) {
        removeInvoice.splice(removeIndex, 1);
      }
      return {
        ...state,
        invoices: {
          ...state.invoices,
          result: removeInvoice,
        },
      };
    case actionTypes.ADD_INVOICE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          result: [...state.invoices.result, action.payload],
        },
      };
    case actionTypes.LOG_OUT:
      return {
        invoices: {
          result: [],
          __metadata: {},
        },
      };
    default:
      return state;
  }
}
