import * as actionTypes from '../../actionTypes';
import moment from 'moment';
const m = moment();

const initialState = {
  dailyStock: [],
  date: m.add({hours: 5, minutes: 30}).utc().format(),
  analysis: [],
};

export default function dailyStockReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_DAILY_STOCKS:
      return {
        ...state,
        dailyStock: action.payload,
      };
    case actionTypes.UPDATE_CLOSING_STOCK:
      const update = [...state.dailyStock];
      if (update.length > 0) {
        const updateIndex = update[0].stock.findIndex(
          (a) => a.inventory_item_id._id === action.payload.id
        );
        if (updateIndex > -1) {
          update[0].stock[updateIndex].closing_stock = action.payload.value;
        }
      }
      return {
        ...state,
        dailyStock: update,
      };
    case actionTypes.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case actionTypes.INIT_STOCK_ANALYSIS:
      return {
        ...state,
        analysis: action.payload,
      };
    case actionTypes.LOG_OUT:
      return {
        dailyStock: [],
        date: m.add({hours: 5, minutes: 30}).utc().format(),
        analysis: [],
      };
    default:
      return state;
  }
}
