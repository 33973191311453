import React, { lazy, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { lightTheme, darkTheme } from './utils/themes/theme';
import './App.scss';
import { Route, Switch } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import suspenseHoc from './hoc/suspenseHoc';
import PrivateAuthHoc from './hoc/PrivateAuthHoc';
import LandingAuthHoc from './hoc/LandingAuthHoc';
import headerHoc from './hoc/headerHoc';
import LoadingModal from './modules/LoadingModal';
import NotFound from './modules/NotFound';
import {
  getCutCodes,
  getIngredientItem,
  getInventoryCategory,
  getInventorySuperCategory,
  getSemiProcessedCategory,
  getSemiProcessedItem,
  getStockTemplates,
  getUnits,
} from './services/actions/stockActions';
import { useDispatch, useSelector } from 'react-redux';
const LandingPage = lazy(() => import('./pages/LandingPage'));
const InventoryPage = lazy(() => import('./pages/InventoryPage'));
const ConfigureStockPage = lazy(() => import('./pages/ConfigureStockPage'));
const PurchaseInvoicePage = lazy(() => import('./pages/PurchaseInvoicePage'));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem('appVersion');
      if (!appVersion) {
        window.localStorage.setItem('appVersion', '2.02.12i');
      } else if (appVersion !== '2.02.12i') {
        toast.info('updating new version');
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem('appVersion', '2.02.12i');
          window.location.reload();
        }, 2000);
      }
    };
  }, []);

  const storeID = useSelector(
    (state) => state.auth.restData && state.auth.restData.store_id
  );

  useEffect(() => {
    if (storeID) {
      dispatch(getUnits(storeID));
      dispatch(getInventorySuperCategory(storeID));
      dispatch(getInventoryCategory(storeID));
      dispatch(getIngredientItem());
      dispatch(getCutCodes(storeID));
      dispatch(getSemiProcessedCategory(storeID));
      dispatch(getSemiProcessedItem());
      dispatch(getStockTemplates(storeID));
    }
  }, [dispatch, storeID]);

  const theme = window.localStorage.theme ? window.localStorage.theme : 'light';
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <LoadingModal />
      <Switch>
        <PrivateAuthHoc
          exact
          path="/dashboard"
          component={suspenseHoc(headerHoc(InventoryPage))}
        />
        <PrivateAuthHoc
          exact
          path="/configure-stock"
          component={suspenseHoc(headerHoc(ConfigureStockPage))}
        />
        <PrivateAuthHoc
          exact
          path="/purchase-invoice"
          component={suspenseHoc(headerHoc(PurchaseInvoicePage))}
        />
        <LandingAuthHoc exact path="/" component={suspenseHoc(LandingPage)} />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
    </ThemeProvider>
  );
}

export default App;
