import * as actionTypes from '../../actionTypes';

const initialState = {
  vendors: [],
};

export default function vendorReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.INIT_VENDORS:
      return {
        ...state,
        vendors: action.payload,
      };
    case actionTypes.ADD_VENDOR: {
      const addVendor = [...state.vendors];
      addVendor.push(action.payload);
      return {
        ...state,
        vendors: addVendor,
      };
    }
    case actionTypes.REMOVE_VENDOR: {
      const removeVendor = [...state.vendors];
      const index = removeVendor.findIndex((a) => a._id === action.payload);
      if (index > -1) {
        removeVendor.splice(index, 1);
      }
      return {
        ...state,
        vendors: removeVendor,
      };
    }
    case actionTypes.LOG_OUT:
      return {
        vendors: [],
      };
    default:
      return state;
  }
}
