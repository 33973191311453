import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import {setLoading} from '../miscActions';
import {toast} from 'react-toastify';

export const getItemInfo = () => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    const restID =
      getState().auth.restData && getState().auth.restData.store_id;
    http
      .get(`${API_URL}/stores/${restID}/items?limit=1000&page=1&type=all`)
      .then((res) => {
        dispatch({type: 'INIT_ITEMS', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getInventoryList = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/inventory_items`)
      .then((res) => {
        dispatch({type: 'INIT_INVENTORY', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to get inventory list');
        dispatch(setLoading(false));
      });
  };
};

export const addInventory = (data) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/inventory_items`, data)
      .then(() => {
        dispatch(getInventoryList());
      })
      .catch(() => {
        toast.error('unable to add in inventory');
        dispatch(setLoading(false));
      });
  };
};

export const updateInventory = (data, id, cb) => {
  return (dispatch) => {
    http
      .put(`${API_URL}/inventory_items/${id}`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_INVENTORY', payload: res.data});
        cb();
      })
      .catch(() => {
        toast.error('unable to update in inventory');
        cb();
      });
  };
};

export const updateInvoice = (data, id, cb) => {
  return (dispatch) => {
    http
      .put(`${API_URL}/store_invoice/${id}`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_INVOICE', payload: res.data});
        cb();
      })
      .catch(() => {
        toast.error('unable to update invoice');
        cb();
      });
  };
};

export const updateInventoryKitchen = (data, id, cb) => {
  return (dispatch) => {
    http
      .put(`${API_URL}/inventory_items/${id}/kitchen`, data)
      .then((res) => {
        dispatch({type: 'UPDATE_KITCHEN', payload: res.data});
        cb();
      })
      .catch(() => {
        toast.error('unable to update kitchen inventory');
        cb();
      });
  };
};

export const deleteInventory = (id) => {
  return (dispatch) => {
    http
      .delete(`${API_URL}/inventory_items/${id}`)
      .then(() => {
        dispatch({type: 'DELETE_INVENTORY', payload: id});
      })
      .catch(() => {
        toast.error('unable to delete from inventory');
      });
  };
};

// export const deleteInvoice = (id) => {
//   return (dispatch) => {
//     http
//       .delete(`${API_URL}/store_invoice/${id}`)
//       .then(() => {
//         dispatch({type: 'DELETE_INVOICE', payload: id});
//         // dispatch(getInvoiceList());
//         dispatch(getInventoryList());
//       })
//       .catch(() => {
//         toast.error('unable to delete invoice');
//       });
//   };
// };

export const getRecipeList = (id, cb) => {
  return (dispatch) => {
    cb(true);
    http
      .get(`${API_URL}/recipes/${id}`)
      .then((res) => {
        if (res.data.recipe) {
          dispatch({type: 'INIT_RECIPE', payload: res.data});
        }
        if (res.data._id) {
          dispatch({type: 'RECIPE_UPDATE', payload: true});
        } else {
          dispatch({type: 'RECIPE_UPDATE', payload: false});
        }
        cb(false);
      })
      .catch(() => {
        dispatch({type: 'RECIPE_UPDATE', payload: false});
        cb(false);
      });
  };
};

export const updateRecipe = (data, cb) => {
  return (dispatch) => {
    cb(true);
    http
      .put(`${API_URL}/recipes/${data.item_id}`, data)
      .then((res) => {
        dispatch({type: 'INIT_RECIPE', payload: res.data.recipe});
        if (res.data._id) {
          dispatch({type: 'RECIPE_UPDATE', payload: true});
        } else {
          dispatch({type: 'RECIPE_UPDATE', payload: false});
        }
        cb(false);
      })
      .catch(() => {
        toast.error('unable to update');
        dispatch({type: 'RECIPE_UPDATE', payload: false});
        cb(false);
      });
  };
};

export const addRecipe = (data, cb) => {
  return (dispatch) => {
    cb(true);
    http
      .post(`${API_URL}/recipes/${data.item_id}`, data)
      .then((res) => {
        dispatch({type: 'INIT_RECIPE', payload: res.data.recipe});
        if (res.data._id) {
          dispatch({type: 'RECIPE_UPDATE', payload: true});
        } else {
          dispatch({type: 'RECIPE_UPDATE', payload: false});
        }
        cb(false);
      })
      .catch(() => {
        toast.error('unable to add in recipe');
        dispatch({type: 'RECIPE_UPDATE', payload: false});
        cb(false);
      });
  };
};

// export const addInvoice = (data) => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     http
//       .post(`${API_URL}/store_invoice`, data)
//       .then(() => {
//         // dispatch(getInvoiceList());
//         dispatch(getInventoryList());
//       })
//       .catch(() => {
//         toast.error('unable to add in inventory');
//         dispatch(setLoading(false));
//       });
//   };
// };

// export const getInvoiceList = () => {
//   return (dispatch) => {
//     dispatch(setLoading(true));
//     http
//       .get(`${API_URL}/store_invoice?limit=1000&page=1`)
//       .then((res) => {
//         dispatch({type: 'INIT_INVOICE', payload: res.data});
//         dispatch(setLoading(false));
//       })
//       .catch(() => {
//         toast.error('unable to get invoice list');
//         dispatch(setLoading(false));
//       });
//   };
// };

export const fetchCurrentItem = (itemId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/inventory_items/${itemId}`)
      .then((res) => {
        dispatch({type: 'FETCH_CURRENT_ITEM', payload: res.data});
        dispatch(setLoading(false));
      })
      .catch(() => {
        toast.error('unable to get current item');
        dispatch(setLoading(false));
      });
  };
};

export const setCurrentItem = (item) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    dispatch({type: 'SET_CURRENT_ITEM', payload: item});
    dispatch(setLoading(false));
  };
};
