import React from 'react';
import {useStyles} from '../styles';
import RefreshIcon from '../svgs/RefreshIcon';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchSource,
  getInventoryList,
  getInvoices,
  getVendors,
} from '../../../services/actions';

function HotMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const restID = useSelector(
    (state) => state.auth.restData && state.auth.restData.store_id
  );

  const handleRefreshClick = () => {
    dispatch(fetchSource(restID));
    dispatch(getVendors());
    dispatch(getInvoices());
    dispatch(getInventoryList());
  };
  const icons = [
    {
      component: <RefreshIcon />,
      handleClick: handleRefreshClick,
      active: true,
    },
  ];

  return (
    <div className={classes.iconFlex}>
      {icons.map(
        (icon, index) =>
          icon.active && (
            <IconButton key={index} onClick={icon.handleClick}>
              <Badge
                className={classes.badge}
                badgeContent={icon.count ? icon.count : 0}
              >
                {icon.component}
              </Badge>
            </IconButton>
          )
      )}
    </div>
  );
}

export default HotMenu;
