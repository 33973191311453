import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function ConfigureStockIcon() {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M455.68,212.48c-5.12-23.04-15.36-46.08-28.16-66.56l25.6-53.76l-35.84-35.84l-53.76,25.6
        c-20.48-12.8-40.96-20.48-64-25.6L281.6,0h-51.2l-20.48,56.32c-23.04,5.12-43.52,15.36-64,28.16l-53.76-25.6L56.32,92.16
        l25.6,53.76c-12.8,20.48-20.48,40.96-25.6,64L0,230.4v51.2l56.32,20.48c5.12,23.04,15.36,46.08,25.6,64l-25.6,53.76l35.84,35.84
        l53.76-25.6c20.48,12.8,40.96,23.04,64,28.16L230.4,512h51.2l20.48-56.32c7.68-2.56,17.92-5.12,25.6-7.68l-40.96-40.96
        C189.44,424.96,102.4,353.28,102.4,256c0-84.48,69.12-153.6,153.6-153.6c94.72,0,166.4,84.48,151.04,174.08L450.56,320
        c2.56-7.68,5.12-12.8,5.12-20.48L512,281.6v-51.2L455.68,212.48z"
      />
      <path
        d="M501.76,445.44L350.72,294.4c35.84-79.36-38.4-163.84-120.32-140.8l46.08,46.08c20.48,20.48,20.48,53.76,0,71.68
        c-17.92,20.48-53.76,20.48-71.68,0l-46.08-43.52c-23.04,71.68,51.2,153.6,135.68,120.32L445.44,499.2
        c15.36,15.36,38.4,15.36,53.76,0C514.56,486.4,514.56,460.8,501.76,445.44z"
      />
    </SvgIcon>
  );
}

export default ConfigureStockIcon;
