import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import authReducers from './authReducers';
import miscReducers from './miscReducers';
import inventoryReducer from './inventoryReducers';
import vendorReducers from './vendorReducers';
import invoiceReducers from './invoiceReducers';
import dailyStockReducers from './dailyStockReducers';
import purchaseReducers from './purchaseReducers';
import garbageReducers from './garbageReducers';
import stockReducers from './stockReducers';

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducers,
    misc: miscReducers,
    inventory: inventoryReducer,
    vendors: vendorReducers,
    invoices: invoiceReducers,
    dailyStocks: dailyStockReducers,
    purchase: purchaseReducers,
    garbage: garbageReducers,
    stock: stockReducers,
  });

export default reducers;
