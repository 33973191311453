import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    width: '100%',
    color: theme.palette.text.secondary,
    borderLeft: '3px solid transparent',
    cursor: 'pointer',
  },
  activeRoot: {
    borderLeft: '3px solid #FEBF2C',
    backgroundColor: '#FFF7E6',
    color: '#212121',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: theme.spacing(20),
  },
  labelIcon: {
    marginRight: theme.spacing(6),
  },
}));
