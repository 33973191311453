import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';

export const updateClosingStock = (data, date) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    const url = `${API_URL}/stocks?date=${date}`;
    http
      .post(url, data)
      .then(() => {
        dispatch(getDailyStocks(date));
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const setDate = (date) => ({
  type: actionTypes.SET_DATE,
  payload: date,
});

export const getDailyStocks = (date) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stocks?date=${date}`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_DAILY_STOCKS, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addDailyStocks = (data, cb, stockID) => {
  return (dispatch, getState) => {
    const date = getState().dailyStocks.date;
    dispatch(setLoading(true));
    let url = `${API_URL}/stocks`;
    if (stockID) {
      url += `?stock_id=${stockID}`;
    }
    http
      .post(url, data)
      .then(() => {
        dispatch(getDailyStocks(date));
        dispatch(setLoading(false));
        cb();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

// export const updateStock = () => {
//   return (dispatch, getState) => {
//     const dailyStock = getState().dailyStocks.dailyStock;
//     const date = getState().dailyStocks.date;
//     const data = [];
//     dispatch(setLoading(true));
//     if (
//       dailyStock.length > 0 &&
//       dailyStock[0].stock &&
//       dailyStock[0].stock.length > 0
//     ) {
//       dailyStock[0].stock.forEach((obj) => {
//         data.push({
//           inventory_item_id: obj.inventory_item_id._id,
//           closing_stock: Number(obj.closing_stock),
//         });
//       });
//     }
//     http
//       .put(`${API_URL}/daily_stocks/${dailyStock[0]._id}`, data)
//       .then(() => {
//         dispatch(getDailyStocks(date));
//         dispatch(setLoading(false));
//       })
//       .catch((err) => {
//         if (err.response && err.response.data && err.response.data.message) {
//           toast.error(err.response.data.message);
//         }
//         dispatch(setLoading(false));
//       });
//   };
// };

export const getStockAnalysis = (from, to) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/stocks/chart?from=${from}&to=${to}`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_STOCK_ANALYSIS, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};
