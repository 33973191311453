import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import * as actionTypes from '../../actionTypes';
import {http} from '../../http';
import {getInvoices} from '../invoiceActions';
import {setLoading} from '../miscActions';

export const getPurchases = () => {
  return (dispatch, getState) => {
    const storeID =
      getState().auth.restData && getState().auth.restData.store_id;
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/purchase_order/${storeID}/store`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_PURCHASE, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addNewPurchases = (data, successCB) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/purchase_order/create`, data)
      .then((res) => {
        dispatch({type: actionTypes.ADD_PURCHASE, payload: res.data});
        successCB();
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const resendPurchaseDetails = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/purchase_order/${id}/resend`)
      .then(() => {
        toast.success('Notification send to vendor again');
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const updatePoPrice = (data, poID) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .put(`${API_URL}/purchase_order/${poID}`, data)
      .then(() => {
        dispatch(setLoading(false));
        dispatch(getPurchases());
      })
      .catch(() => {
        dispatch(setLoading(false));
      });
  };
};

export const movePurchaseToInvoice = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/purchase_order/${id}/convert/invoice`, data)
      .then((res) => {
        dispatch(getPurchases());
        dispatch(getInvoices());
        toast.success('Updated successfully');
        if (cb) {
          cb();
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};
