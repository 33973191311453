import {toast} from 'react-toastify';
import {API_URL} from '../../../utils/constants';
import {http} from '../../http';
import {setLoading} from '../miscActions';
import * as actionTypes from '../../actionTypes';

export const getVendors = () => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .get(`${API_URL}/vendor/store`)
      .then((res) => {
        dispatch({type: actionTypes.INIT_VENDORS, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const addNewVendor = (data, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .post(`${API_URL}/vendor`, data)
      .then((res) => {
        if (cb) {
          cb();
        }
        dispatch({type: actionTypes.ADD_VENDOR, payload: res.data});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};

export const removeVendor = (id, cb) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    http
      .delete(`${API_URL}/vendor/${id}`)
      .then(() => {
        if (cb) {
          cb();
        }
        dispatch({type: actionTypes.REMOVE_VENDOR, payload: id});
        dispatch(setLoading(false));
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setLoading(false));
      });
  };
};
