const initialState = {
  items: [],
  inventoryList: [],
  recipe: {},
  updateRecipe: false,
  currentItem: {},
};

export default function inventoryReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_ITEMS':
      return {
        ...state,
        items: action.payload,
      };
    case 'INIT_INVENTORY':
      return {
        ...state,
        inventoryList: action.payload,
      };
    case 'UPDATE_INVENTORY':
      const value = [...state.inventoryList];
      const findIndex = value.findIndex((a) => a._id === action.payload._id);
      if (findIndex > -1) {
        value[findIndex] = {...action.payload};
      }
      return {
        ...state,
        inventoryList: value,
      };
    case 'UPDATE_KITCHEN':
      const item = [...state.inventoryList];
      const index = item.findIndex((a) => a._id === action.payload._id);
      if (index > -1) {
        item[index] = {...action.payload};
      }
      return {
        ...state,
        inventoryList: item,
      };
    case 'DELETE_INVENTORY':
      const delValue = [...state.inventoryList];
      const delIndex = delValue.findIndex((a) => a._id === action.payload);
      if (delIndex > -1) {
        delValue.splice(delIndex, 1);
      }
      return {
        ...state,
        inventoryList: delValue,
      };
    case 'INIT_RECIPE':
      return {
        ...state,
        recipe: action.payload,
      };
    case 'RECIPE_UPDATE':
      return {
        ...state,
        updateRecipe: action.payload,
      };
    case 'RESET_RECIPE':
      return {
        ...state,
        recipe: {},
      };
    case 'FETCH_CURRENT_ITEM':
      return {
        ...state,
        currentItem: action.payload,
      };
    case 'SET_CURRENT_ITEM':
      return {
        ...state,
        currentItem: action.payload,
      };
    case 'LOG_OUT':
      return {
        items: [],
        inventoryList: [],
        recipe: {},
        updateRecipe: false,
        currentItem: {},
      };
    default:
      return state;
  }
}
