import React from 'react';
import {useStyles} from '../styles';
import {useSelector} from 'react-redux';
import logo from '../../../pages/LandingPage/assets/logo.png';

function RestInfo() {
  const classes = useStyles();
  const restaurant = useSelector((state) => state.auth.restaurant);

  return (
    <div className={classes.restInfoRoot}>
      <div className={classes.restInfoFlex}>
        <div className={classes.restInfoLogo}>
          <img
            src={
              restaurant.logo && restaurant.logo !== '' ? restaurant.logo : logo
            }
            height={72}
            width={72}
            alt={''}
          />
        </div>
        <div className={classes.restInfoText}>
          <div className={classes.restTitle}>
            {restaurant.name && restaurant.name['en']}
          </div>
          <div className={classes.restAddress}>
            {restaurant && restaurant.address && restaurant.address.line1}
          </div>
          <div className={classes.restAddress}>
            {restaurant &&
              restaurant.address &&
              restaurant.address.city &&
              restaurant.address.city.name}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RestInfo;
