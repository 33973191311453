import * as actionTypes from '../../actionTypes';
import {getRestData} from '../authActions';
// import {http} from '../../http';
import {API_URL} from '../../../utils/constants';
import axios from 'axios';

export const setLoading = (status) => ({
  type: actionTypes.SET_LOADING,
  payload: status,
});

export const setType = (type) => ({
  type: actionTypes.SELECTED_TYPE,
  payload: type,
});

export const setTypeIndex = (index) => ({
  type: actionTypes.TYPE_INDEX,
  payload: index,
});

export const fetchSource = (id) => {
  return async (dispatch) => {
    await dispatch(getRestData(id));
  };
};

export const ablyAck = (id) => {
  return () => {
    axios
      .put(`${API_URL}/ably/${id}`, {ack: true})
      .then(() => console.log('acknowledged'))
      .catch(() => console.log('unable to ack'));
  };
};
