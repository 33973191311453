import * as actionTypes from '../../actionTypes';

const initialState = {
  baseUnits: ['litre', 'kg', 'piece'],
  loading: false,
  units: [],
  superCategories: [],
  categories: [],
  items: [],
  cutCodes: [],
  semiProcessedCategories: [],
  semiProcessedItems: [],
  semiProcessedRecipe: {},
  templates: [],
};

export default function stockReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.STOCK_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.INIT_STOCK_UNITS:
      return {
        ...state,
        units: action.payload,
      };
    case actionTypes.ADD_STOCK_UNITS:
      const addUnits = [...state.units];
      addUnits.push(action.payload);
      return {
        ...state,
        units: addUnits,
      };
    case actionTypes.UPDATE_STOCK_UNITS:
      const updateUnits = [...state.units];
      const updateUnitIndex = updateUnits.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateUnitIndex > -1) {
        updateUnits[updateUnitIndex] = action.payload;
      }
      return {
        ...state,
        units: updateUnits,
      };
    case actionTypes.DELETE_STOCK_UNIT:
      const deleteUnits = [...state.units];
      const deleteUnitsIndex = deleteUnits.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteUnitsIndex > -1) {
        deleteUnits.splice(deleteUnitsIndex, 1);
      }
      return {
        ...state,
        units: deleteUnits,
      };
    case actionTypes.INIT_STOCK_SUPER_CATEGORY:
      return {
        ...state,
        superCategories: action.payload,
      };
    case actionTypes.ADD_STOCK_SUPER_CATEGORY:
      const addSuperCategory = [...state.superCategories];
      addSuperCategory.push(action.payload);
      return {
        ...state,
        superCategories: addSuperCategory,
      };
    case actionTypes.UPDATE_STOCK_SUPER_CATEGORY:
      const updateSuperCategory = [...state.superCategories];
      const updateSuperCategoryIndex = updateSuperCategory.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateSuperCategoryIndex > -1) {
        updateSuperCategory[updateSuperCategoryIndex] = action.payload;
      }
      return {
        ...state,
        superCategories: updateSuperCategory,
      };
    case actionTypes.DELETE_STOCK_SUPER_CATEGORY:
      const deleteSuperCategory = [...state.superCategories];
      const deleteSuperCategoryIndex = deleteSuperCategory.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteSuperCategoryIndex > -1) {
        deleteSuperCategory.splice(deleteSuperCategoryIndex, 1);
      }
      return {
        ...state,
        categories: deleteSuperCategory,
      };
    case actionTypes.INIT_STOCK_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.ADD_STOCK_CATEGORY:
      const addCategory = [...state.categories];
      addCategory.push(action.payload);
      return {
        ...state,
        categories: addCategory,
      };
    case actionTypes.UPDATE_STOCK_CATEGORY:
      const updateCategory = [...state.categories];
      const updateCategoryIndex = updateCategory.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateCategoryIndex > -1) {
        updateCategory[updateCategoryIndex] = action.payload;
      }
      return {
        ...state,
        categories: updateCategory,
      };
    case actionTypes.DELETE_STOCK_CATEGORY:
      const deleteCategory = [...state.categories];
      const deleteCategoryIndex = deleteCategory.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteCategoryIndex > -1) {
        deleteCategory.splice(deleteCategoryIndex, 1);
      }
      return {
        ...state,
        categories: deleteCategory,
      };
    case actionTypes.INIT_STOCK_ITEM:
      return {
        ...state,
        items: action.payload,
      };
    case actionTypes.ADD_STOCK_ITEM:
      const addItem = [...state.items];
      addItem.push(action.payload);
      return {
        ...state,
        items: addItem,
      };
    case actionTypes.UPDATE_STOCK_ITEM:
      const updateItem = [...state.items];
      const updateItemIndex = updateItem.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateItemIndex > -1) {
        updateItem[updateItemIndex] = action.payload;
      }
      return {
        ...state,
        items: updateItem,
      };
    case actionTypes.DELETE_STOCK_ITEM:
      const deleteItem = [...state.items];
      const deleteItemIndex = deleteItem.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteItemIndex > -1) {
        deleteItem.splice(deleteItemIndex, 1);
      }
      return {
        ...state,
        items: deleteItem,
      };
    case actionTypes.INIT_STOCK_CUTCODE:
      return {
        ...state,
        cutCodes: action.payload,
      };
    case actionTypes.ADD_STOCK_CUTCODE:
      const addCutCode = [...state.cutCodes];
      addCutCode.push(action.payload);
      return {
        ...state,
        cutCodes: addCutCode,
      };
    case actionTypes.UPDATE_STOCK_CUTCODE:
      const updateCutCode = [...state.cutCodes];
      const updateCutCodeIndex = updateCutCode.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateCutCodeIndex > -1) {
        updateCutCode[updateCutCodeIndex] = action.payload;
      }
      return {
        ...state,
        cutCodes: updateCutCode,
      };
    case actionTypes.DELETE_STOCK_CUTCODE:
      const deleteCutCode = [...state.cutCodes];
      const deleteCutCodeIndex = deleteCutCode.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteCutCodeIndex > -1) {
        deleteCutCode.splice(deleteCutCodeIndex, 1);
      }
      return {
        ...state,
        cutCodes: deleteCutCode,
      };
    case actionTypes.INIT_STOCK_SEMI_PROCESSED_CATEGORY:
      return {
        ...state,
        semiProcessedCategories: action.payload,
      };
    case actionTypes.ADD_STOCK_SEMI_PROCESSED_CATEGORY:
      const addSPCategory = [...state.semiProcessedCategories];
      addSPCategory.push(action.payload);
      return {
        ...state,
        semiProcessedCategories: addSPCategory,
      };
    case actionTypes.UPDATE_STOCK_SEMI_PROCESSED_CATEGORY:
      const updateSPCategory = [...state.semiProcessedCategories];
      const updateSPCategoryIndex = updateSPCategory.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateSPCategoryIndex > -1) {
        updateSPCategory[updateSPCategoryIndex] = action.payload;
      }
      return {
        ...state,
        semiProcessedCategories: updateSPCategory,
      };
    case actionTypes.DELETE_STOCK_SEMI_PROCESSED_CATEGORY:
      const deleteSPCategory = [...state.semiProcessedCategories];
      const deleteSPCategoryIndex = deleteSPCategory.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteSPCategoryIndex > -1) {
        deleteSPCategory.splice(deleteSPCategoryIndex, 1);
      }
      return {
        ...state,
        semiProcessedCategories: deleteSPCategory,
      };
    case actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM:
      return {
        ...state,
        semiProcessedItems: action.payload,
      };
    case actionTypes.ADD_STOCK_SEMI_PROCESSED_ITEM:
      const addSPItem = [...state.semiProcessedItems];
      addSPItem.push(action.payload);
      return {
        ...state,
        semiProcessedItems: addSPItem,
      };
    case actionTypes.UPDATE_STOCK_SEMI_PROCESSED_ITEM:
      const updateSPItem = [...state.semiProcessedItems];
      const updateSPItemIndex = updateSPItem.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateSPItemIndex > -1) {
        updateSPItem[updateSPItemIndex] = action.payload;
      }
      return {
        ...state,
        semiProcessedItems: updateSPItem,
      };
    case actionTypes.DELETE_STOCK_SEMI_PROCESSED_ITEM:
      const deleteSPItem = [...state.semiProcessedItems];
      const deleteSPItemIndex = deleteSPItem.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteSPItemIndex > -1) {
        deleteSPItem.splice(deleteSPItemIndex, 1);
      }
      return {
        ...state,
        semiProcessedItems: deleteSPItem,
      };
    case actionTypes.INIT_STOCK_SEMI_PROCESSED_ITEM_RECIPE:
      return {
        ...state,
        semiProcessedRecipe: action.payload,
      };
    case actionTypes.RESET_STOCK_SEMI_PROCESSED_ITEM_RECIPE:
      return {
        ...state,
        semiProcessedRecipe: {},
      };
    case actionTypes.INIT_STOCK_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case actionTypes.ADD_STOCK_TEMPLATES:
      const addTemplates = [...state.templates];
      addTemplates.push(action.payload);
      return {
        ...state,
        templates: addTemplates,
      };
    case actionTypes.UPDATE_STOCK_TEMPLATES:
      const updateTemplates = [...state.templates];
      const updateTemplatesIndex = updateTemplates.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateTemplatesIndex > -1) {
        updateTemplates[updateTemplatesIndex] = action.payload;
      }
      return {
        ...state,
        templates: updateTemplates,
      };
    case actionTypes.DELETE_STOCK_TEMPLATES:
      const deleteTemplates = [...state.templates];
      const deleteTemplatesIndex = deleteTemplates.findIndex(
        (a) => a._id === action.payload
      );
      if (deleteTemplatesIndex > -1) {
        deleteTemplates.splice(deleteTemplatesIndex, 1);
      }
      return {
        ...state,
        templates: deleteTemplates,
      };
    default:
      return state;
  }
}
