import * as actionTypes from '../../actionTypes';

const initialState = {
  loading: false,
  type: 'dinein',
  typeIndex: 0,
};

export default function miscReducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SELECTED_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case actionTypes.TYPE_INDEX:
      return {
        ...state,
        typeIndex: action.payload,
      };
    case actionTypes.LOG_OUT:
      return {
        loading: false,
        type: 'dinein',
        typeIndex: 0,
      };
    default:
      return state;
  }
}
